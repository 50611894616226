import { Fragment, useState, forwardRef, useRef, useEffect } from "react";
import { Link } from "react-router-dom";
import "../Inputs/InputRe.css";
import "./Autocomplete.scss"
import useCreateUrl from '../../../Hooks/useCreateUrl';

const Autocomplete = forwardRef((props, ref) => {
  const { options, setValue, setAddress, searchTerm, onBlurFunction, setSearchTerm, dropdownName, description, edit, labelText, inputSize, text, label, btnText, optionalField, setButtonEvent, helpField, inputFieldType, route, propValue, onClickFunction } = props;
  const [showDropdown, setShowDropdown] = useState(false);
  const [filteredOptions, setFilteredOptions] = useState([]);
  const [editable, setEditable] = useState(false);
  const [tempValue, setTempValue] = useState();
  const createUrl = useCreateUrl();
  const listRef = useRef(null);
  const [selectedIndex, setSelectedIndex] = useState(-1);
  const [alreadyHasUpdated, setAlreadyHasUpdated] = useState(false)


  useEffect(() => {
    if (propValue === "") {
      setTempValue("")
      setSearchTerm("")  //handling of local state for autocomplete
    }
    else {
      setTempValue(propValue)
      setSearchTerm(propValue)
    }
  }, [propValue])


  useEffect(() => {
    
    setFilteredOptions(options)  //initialising list 

  }, [options])


  //keyboard handling of the autocomplete dropdown
  //for using up-down arrows to select options.

  useEffect(() => {

    if(selectedIndex > -1 && selectedIndex < listRef.current.children?.length){
    
    if (listRef.current && listRef.current.children) {
      const listItems = listRef.current.children;
      if (listItems?.length > 0) {
        listItems[selectedIndex]?.classList.add('selected');
        for (let i = 0; i < listItems?.length; i++) {
          if (i !== selectedIndex) {
            listItems[i].classList.remove('selected');
          }
        }


        const selectedListItem = listItems[selectedIndex];
        const listRect = listRef.current.getBoundingClientRect();
        const itemRect = selectedListItem.getBoundingClientRect();
        const offset = itemRect.top - listRect.top;
        const listHeight = listRect.bottom - listRect.top;
        const itemHeight = itemRect.bottom - itemRect.top;

        if (offset < 0) {
          listRef.current.scrollTop += offset;
        } else if (offset + itemHeight > listHeight) {
          listRef.current.scrollTop += (offset + itemHeight) - listHeight;
        }
      }
    }
  }
  }, [selectedIndex]);

  function handleKeyDown(event) {

   
    if (listRef.current && event.key === 'ArrowDown') {
      event.preventDefault();
      setSelectedIndex(prevIndex => {
        if (prevIndex === listRef.current.children?.length - 1) {
          return prevIndex;
        }
        else if (prevIndex === 0) {

          return prevIndex + 1;
        }
        else {
          return prevIndex + 1;
        }
      });
    } else if (listRef.current && event.key === 'ArrowUp') {
      event.preventDefault();
      setSelectedIndex(prevIndex => {
        if (prevIndex === 0) {
          return prevIndex;
        } else {
          return prevIndex - 1;
        }
      });
    } else if (event.key == 'Enter') {

      const currentOption = filteredOptions[selectedIndex];
      const address = [
        currentOption?.addressline1,
        currentOption?.town,
        currentOption?.country,
        currentOption?.postcode
      ].filter(Boolean).join(', ');
    
      setAlreadyHasUpdated(true);
      setValue({ "name": currentOption?.companyname, "id": currentOption?.companyid }); setAddress(address); setSearchTerm(currentOption?.companyname); setShowDropdown(!showDropdown)

    }
  }

  let inputWidth = () => {
    switch (inputSize) {
      case 'full-width':
        return "full-width"
      case 'large':
        return "large"
      case 'medium':
        return "medium"
      case 'small':
        return "small"
      default:
        return "large"
    }
  }

  //filter func in case for front end handling of lists

  const filterFunc = () => {
    if (dropdownName === "company") {
      return options.filter((op) =>
        (op.companyname.toLowerCase().includes(searchTerm?.toLowerCase()) || op?.postcode?.toLowerCase().includes(searchTerm.toLowerCase()))
      )
    }
  }


  //for highlighting mapped letters in the list

  function highlightMatch(text, match) {
    return text.replace(
      new RegExp(`(${match})`, "gi"),
      '<span class="highlight">$1</span>'
    );
  }


  const labelField = () => {
    return <div class="label-wrapper">
      <div class="label-text">
        <label className="label" htmlFor={props.id}> {props.label} </label>
        {description ? <p className="label-description">{description}</p> : ""}

        {(props.specs === "search_input") && <p className="meta">Type first letters to find..</p>}
      </div>
      <div class="label-aid">
        {optionalField ? <div className="required-text">Optional </div> : ""}
        {/* {helpField ? <div className="help-text"> <a href="#" className="help-text-btn" onClick={helpLinkHandler}> <span className={!showHelp ? "question-icon" : "close-icon"} ></span> </a> </div> : ""} */}
      </div>
    </div>
  }


  function escapeSpecialChars(string) {
    return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // $& means the whole matched string
  }
  
  
  //rendering Suggestions on the basis of typed in letters

  function renderSuggestion(option) {
    const optionText1 = option["companyname"];
    const optionText2 = option["postcode"];
    const escapedTerm = escapeSpecialChars(searchTerm);
    const regex = new RegExp(`(${escapedTerm})`, 'gi');
    // const regex = new RegExp(`(${searchTerm})`, 'gi');
    const part1 = optionText1.split(regex);
    const part2 = optionText2.split(regex);
    const address = [
        option?.addressline1,
        option?.town,
        option?.country
      ].filter(Boolean).join(', ');

    return (
      <li key={option.companyid} onMouseDown={(e) => { setValue({ "name": option.companyname, "id": option.companyid }); setAddress(`${address}+ ${option?.postcode ? ', ' + option?.postcode : ''}`); setShowDropdown(false); setSearchTerm(option.companyname); setAlreadyHasUpdated(true) }} value={option.companyname}>
        {part1.map((part, index) => {
          if (regex.test(part)) {
            return (
              <span
                key={index}
                dangerouslySetInnerHTML={{
                  __html: highlightMatch(part, searchTerm)    //mapping the companyname
                }}
              />
            );
          }
          return <span key={index}>{part}</span>;
        })} <span className="autocomplete-address">

        {((address || option.postcode) &&
          <> {/*address line 1, town, country*/}
            ({address}{option.postcode && `,`}   {/*postcode*/}
            {option.postcode && (
              part2.map((part, index) => {
                if (regex.test(part)) {
                  return (
                    <span
                      key={index}
                      dangerouslySetInnerHTML={{
                        __html: highlightMatch(part, searchTerm)
                      }}
                    />
                  );
                }
                return <span key={index}>{part}</span>;
              })
            )})
          </>
        )}
        </span>
      </li>
    );
  }


  //search data on the basis of entered letters

  const handleSearch = (e) => {

    if(e.target.value.length===0){
      setFilteredOptions([])
    }
   
    setSelectedIndex(-1)
    setSearchTerm(e.target.value);
    setTempValue(e.target.value);
    setAlreadyHasUpdated(false);

    //setFilteredOptions(filterFunc()) : for frontend handling of lists
  }


  //setting value in Final object only on blur

  const handleOnBlur = (e) => {

    setSelectedIndex(-1);
   
    if (!alreadyHasUpdated) {
      setValue({ "name": e.target.value, "id": null });
      setAddress("");
      if (onBlurFunction && typeof onBlurFunction === 'function') {
        onBlurFunction(e);
      }
    }
  }

  const handleOnFocus = () => {

    setShowDropdown(true);
  }

  return <>
    {(!label && !description && !optionalField && !helpField) ? !labelField() : labelField()}
    
  
    <div class="dropdown-wrapper">
      <div className={`div-wrapper ${inputWidth()}  ${props.invalidClass ? props.invalidClass : props.disabled === "true" ? "" : (searchTerm?.length || propValue?.length > 0) ? "filled" : ""}`}>
        <input
          className={`${inputWidth()}`}
          onChange={(e) => { handleSearch(e) }}
          onFocus={() => { handleOnFocus() }}
          onKeyDown={handleKeyDown}
          onBlur={(e) => { if (handleOnBlur) { handleOnBlur(e) }; setShowDropdown(false) }}
          type="text"
          disabled={edit ? !editable : false}
          label={label}
          autocomplete="off"
          value={tempValue}
          {...props} />
        {/* {route ? 
                 <Link to={route}>{btnText}</Link> 
                 : (edit || onClickFunction) ? 
                  <button type="button" onClick={() => { edit ? setEditable(true) : onClickFunction(null) }}>{btnText}</button> : ""} */}

        {showDropdown && searchTerm?.length > 0 && dropdownName === "company" ?
          <ul ref={listRef} value={'Select' + " " + dropdownName} className="search-dropdown">
            {filteredOptions?.map(renderSuggestion)}
            {/* <li key="0" onMouseDown={(e) => { setValue("Others"); window.location.href = route }}>Others</li> */}
          </ul>
          : ""}
      </div>
    </div>
  </>
})

export default Autocomplete;