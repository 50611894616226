import { useTranslation } from 'react-i18next';
import { useEffect, useState, useContext, forwardRef, useImperativeHandle } from 'react';
import '../../../i18n';
import { format } from 'react-string-format';
import "./Location.scss"
import InputWrapperRefacture from '../../UI/Inputs/InputWrapperRefacture';
import Dropdown from '../../UI/Dropdown/SearchDropdown';
import useCreateUrl from '../../../Hooks/useCreateUrl';
import { PaymentFormContext } from '../../Order/PaymentFormReducer';
import SearchDropdown from '../../UI/Dropdown/SearchDropdown';
import ErrorBox from '../../UI/ErrorBox/ErrorBox';
import { checkIsBlank } from '../../Utilities/FieldValidations';


export function Location(props) {
  const { countryList, setCountryCode, basket, countryCode, id, name, preSelectedCountryCode, selectedAddress, setSelectedAddress, IsFilled, setIsFilled } = props;
  const { t, i18n } = useTranslation();
  const [country, setCountry] = useState("")
  const createUrl = useCreateUrl();

  
  //as n when country state changes
  useEffect(() => {
    
    setSelectedAddress(selectedAddress => ({ ...selectedAddress, COUNTRY: country?.countryname }))
    setCountryCode(country?.countrycode)
    if (country) {
      setIsFilled({ ...IsFilled, COUNTRY: { filled: true, mandatory: true } })
    }
  }, [country])


  //On Mounting
  useEffect(() => {
   
    //in case of only 1 country in the list it will be pre selected
    if (countryList && countryList?.length === 1) {
      setCountry(countryList[0])
      setIsFilled({ ...IsFilled, COUNTRY: { filled: true, mandatory: true } })  //country is an object but selectedAddress.COUNTRY is a string
    }
    if (countryList && countryCode) {
      setCountry(countryList?.find(t => t.countrycode == countryCode))
      setIsFilled({ ...IsFilled, COUNTRY: { filled: true, mandatory: true } })
    }
  }, [])

  return (<>
 
    {//if variants in the basket just have 1 country
      countryList?.length === 1 || !countryList ?
        <div className="item-container">
          <div className="item-wrapper">
            <InputWrapperRefacture
              label={t('checkout.deliverycountrylabel')}
              description={t('checkout.deliverycountrydescription')}
              edit={true}
              route={basket?.originatingurl}
              id={id}
              name={name}
              type="text"
              disabled="true"
              inputFieldType="text"
              btnText={t('checkout.changecountrylabel')}
              invalidClass={IsFilled.COUNTRY && !IsFilled?.COUNTRY?.filled && "Invalid-input"}
              aria-invalid={IsFilled.COUNTRY && !IsFilled?.COUNTRY?.filled}
              aria-describedby="delivery-country-errors"
              inputSize="full-width"
              value={countryList[0]?.countryname}   //value cannot be changed if only 1 value is present in the array.
            // onKeyPress={onTextFieldKeyed}
            />
          </div>
          {IsFilled.COUNTRY && !IsFilled?.COUNTRY?.filled &&
            <span role="alert" id="delivery-country-errors">
              <ErrorBox>{format(t('addressfields.blankerrortext'), "country")}</ErrorBox>
            </span>} </div>

        : countryList?.length > 1 ?

          //if variants in the basket have multiple countries
          <div className="item-container">
            <div className="item-wrapper">
              <SearchDropdown
                label={t('checkout.deliverycountrylabel')}
                description={t('checkout.deliverycountrydescription')}
                id={id}
                route={basket?.originatingurl}
                name={name}
                type="text"
                inputFieldType="text"
                invalidClass={IsFilled.COUNTRY && !IsFilled?.COUNTRY?.filled && "Invalid-input"}
                aria-invalid={IsFilled.COUNTRY && !IsFilled?.COUNTRY?.filled}
                aria-describedby="delivery-country-errors"
                inputSize="full-width"
                setValue={setCountry}
                val={selectedAddress.COUNTRY}
                options={countryList}
                preSelectedCountryCode={preSelectedCountryCode}
                dropdownName="country"
                keyProperty="countrycode"
                nameProperty="countryname"
                valueProperty="countrycode"
              />
            </div>
            {IsFilled.COUNTRY && !IsFilled?.COUNTRY?.filled &&
              <span role="alert" id="delivery-country-errors">
                <ErrorBox>{format(t('addressfields.blankerrortext'), "country")}</ErrorBox>
              </span>}
          </div> : ""}
  </>

  );
}