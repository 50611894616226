import React, { useRef, useState, useEffect } from 'react';
import "./../ThankyouPage.scss";



export function DeliveryDetails(props) {

    const [mergedArray, setMergedArray] = useState([]);
    const [uniqueKeys, setUniqueKeys] = useState([]);
   

    useEffect(() => {
        const mergedMap = new Map();

        // Merge duplicates based on a specific key (e.g., 'id')
        props?.recipients?.forEach(item => {


            const key = item.id;  // Change this to the appropriate key
            if (mergedMap.has(key)) {
                mergedMap.get(key).count += 1;
            } else {
                mergedMap.set(key, { ...item, count: 1 });
            }
        });

        // Extract merged and unique entries
        const mergedArray = Array.from(mergedMap.values());
        const uniqueKeysArray = props?.recipients?.filter(item => !mergedMap.has(item.id))
            .map(item => item.id);

        // Update state with merged and unique entries
        setMergedArray(mergedArray);
        setUniqueKeys(uniqueKeysArray);


    }, [props?.recipients]);


    const Delivery = () => {

        return <>

            <div className="item-wrapper">
                <h4>Delivery Details</h4>
            </div>
            <div className="item-wrapper">
                {mergedArray && mergedArray?.map((recipientDetails, index) => (
                    <>
                        {recipientDetails?.id !== props?.purchaser?.id &&
                            <>
                                <div class="item-field">{recipientDetails?.name}</div>
                                <div class="item-field">{recipientDetails?.email}</div>
                            </>
                        }

                        {(recipientDetails?.Address?.address1?.length > 0 || recipientDetails?.Address?.address2?.length > 0 || recipientDetails?.Address?.address3?.length > 0) &&
                         
                            <div className="item-field">
                                <p><strong>Delivery Address</strong></p>
                                <p>{recipientDetails.Address?.companyName} {recipientDetails.Address.companyName && ', '}
                                    {recipientDetails.Address?.address1} {recipientDetails.Address.address1 && ', '}
                                    {recipientDetails.Address?.address2} {recipientDetails.Address.address2 && ', '}
                                    {recipientDetails.Address?.address3} {recipientDetails.Address.address3 && ', '}
                                    {recipientDetails.Address?.town}  {recipientDetails.Address.town && ', '}
                                    {recipientDetails.Address?.county}  {recipientDetails.Address.county && ', '}
                                    {recipientDetails.Address?.country} {recipientDetails.Address.country && ', '}
                                    {recipientDetails.Address?.postcode}  </p>
                            </div>
                        }
                    </>
                ))}
            </div>
        </>

    }
    return (
        <>{mergedArray?.map((recipientDetails, index) => ( 
            <>  
                {((recipientDetails?.Address?.address1?.length > 0 || recipientDetails?.Address?.address2?.length > 0 || recipientDetails?.Address?.address3?.length > 0) || recipientDetails.id !== props.purchaser.id) &&
                    <>

                        <div className="details-block">
                            <div className="item-container">
                                {Delivery()}
                            </div>
                        </div>
                    </>
                }
                </>

        ))}
           
        </>

    )
            
}
