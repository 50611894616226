
export { getOriginatingUrl, getThankyouPageUrl};


const getOriginatingUrl=(basket, returnUrl)=>{
    let backurl = basket?.originatingurl  ||  sessionStorage.getItem("originatingUrl") || returnUrl ;
    let fallbackUrl=window.location.protocol + "//" + window.location.host;
    if(!backurl){
        return fallbackUrl;
    }
    else{
        return backurl
    }
    
}

const getThankyouPageUrl=()=>{
    let orderId= sessionStorage.getItem("orderId");
    let url=""
    if(orderId){
        url=window.location.protocol + "//" + window.location.host + "/thankyou/"+ orderId
    }
    else{
        //fallbackUrl
        url=window.location.protocol + "//" + window.location.host
    }
    return url;
}