import { useTranslation } from 'react-i18next';
import useCreateUrl from '../../../Hooks/useCreateUrl';
import '../../../i18n';
import { useEffect, useState } from "react";
import { format } from 'react-string-format';
import "../Payment.scss"
import { Link, useParams } from 'react-router-dom';
import InputWrapperRefacture from '../../UI/Inputs/InputWrapperRefacture';
import { emailRegexUtils } from '../../Utilities/EmailRegex';
import ErrorBox from '../../UI/ErrorBox/ErrorBox';
import setStateAsync from '../../Utilities/SetStateAsync';
import { checkIsBlank, checkIsInvalid } from '../../Utilities/FieldValidations';
import Button from '../../UI/Buttons/Button';
import { APIPost } from '../../API/APIRequest';
import { useAuth } from '../../Authentication/AuthenticationProvider';


export function RecipientDeliveryDetails({ userEmail, setIsFilled, setIsInvalid, IsInvalid, IsFilled, recipientDetails, setRecipientDetails, loggedIn, basket, detailType}) {
  const createUrl = useCreateUrl()
  const { t, i18n } = useTranslation();
  const [details, setDetails] = useState({});
  const [IsFilledRecipient, setIsFilledRecipient] = useState({})
  let text = format(t('checkout.usersignedintext'),)
  const { environment } = useParams();

  const handleOnBlur = (e, fieldType) => {
    Validation(e, fieldType);
  }

  const Validation = (e, fieldType) => {
    let value = e.target.value;
    let type = fieldType;
    let isValid= false;

    setStateAsync(() => {
       if (checkIsBlank(value)){
          setIsFilledRecipient(IsFilledRecipient => ({ ...IsFilledRecipient, [fieldType]: {...IsFilledRecipient[fieldType], filled:false, mandatory:true} }))
           }
      else {
          setIsFilledRecipient(IsFilledRecipient => ({ ...IsFilledRecipient, [fieldType]: {...IsFilledRecipient[fieldType], filled:true, mandatory:true} }))
          setRecipientDetails(recipientDetails => ({ ...recipientDetails, [fieldType]: value }));

          if (checkIsInvalid(value, type)) {
              setIsInvalid(IsInvalid => ({ ...IsInvalid, [fieldType]:  {...IsInvalid[fieldType], invalid :true, mandatory:true} }))
              }
          else {
              setIsInvalid(IsInvalid => ({ ...IsInvalid, [fieldType]: {...IsInvalid[fieldType], invalid :false, mandatory:true} }))
              }}
    })
 }

  useEffect(()=>{
    if(IsFilled.RECIPIENT?.filled==="not_valid"){
       for(let key in recipientDetails){
           Validation({target:{value:recipientDetails[key]}}, key)
      }
  }}, [IsFilled.RECIPIENT])

  useEffect(()=>{
    for(let key in IsFilledRecipient){
        if(IsFilledRecipient[key].filled && IsFilledRecipient[key].mandatory){
           setIsFilled(IsFilled=> ({ ...IsFilled, RECIPIENT: {filled: true, mandatory:true} }))
         }
        else if(key==="userEmail" && IsFilledRecipient.userEmail===false){
           setIsFilled(IsFilled=> ({ ...IsFilled, RECIPIENT: { filled: false, mandatory:true} }))
         }
        else{
           setIsFilled(IsFilled=> ({ ...IsFilled, RECIPIENT: { filled: false, mandatory:true} }))
         }
      }
  }, [IsFilledRecipient])

  const onTextFieldKeyed = (event) => {
     if ((event.key.match(emailRegexUtils) || []).length > 0) {
        event.preventDefault();
      }
  }

  const handleOnChange=(value, key)=>{
     setDetails(details => ({ ...details, [key]: value })) 
  }
 
  const recipient = () => <div className="guest-user">
    <div className="guest-user-row">
      <div className="item-container">
        <div className="item-wrapper">

          <InputWrapperRefacture
            label={t('checkout.recipientfirstname')}
            inputSize="full-width"
            id="firstname"
            inputFieldType="text"
            onBlur={(e) => { handleOnBlur(e, "firstName") }}
            specs="text_input"
            name="firstname"
            value={details.firstName}
            type="text"
            invalidClass={IsFilledRecipient.firstName && !IsFilledRecipient.firstName.filled && "Invalid-input"}
            onKeyPress={onTextFieldKeyed}
            aria-invalid={IsFilledRecipient.firstName}
            aria-describedby="firstname-blank-error"
            onChange={(e) => { handleOnChange(e.target.value, "firstame") }}
          />

          <span role="alert" id='firstname-blank-error'>{IsFilledRecipient.firstName && !IsFilledRecipient.firstName.filled && <ErrorBox>{t('register.blankfirstname')}</ErrorBox>}</span>
        </div>
      </div>

      <div className="item-container">
        <div className="item-wrapper">
          <InputWrapperRefacture
            label={t('checkout.recipientlastname')}
            inputSize="full-width"
            inputFieldType="text"
            id="lastname"
            onBlur={(e) => { handleOnBlur(e, "lastName") }}
            name="lastname"
            type="text"
            value={details.lastName}
            invalidClass={IsFilledRecipient.lastName && !IsFilledRecipient.lastName.filled && "Invalid-input"}
            specs="text-input"
            onKeyPress={onTextFieldKeyed}
            aria-invalid={IsFilledRecipient.lastName}
            aria-describedby="lastname-blank-error"
            onChange={(e) => { handleOnChange(e.target.value, "lastName") }}
          />
           <span role="alert" id="lastname-blank-error">{IsFilledRecipient.lastName && !IsFilledRecipient.lastName.filled && <ErrorBox>{t('register.blanklastname')}</ErrorBox>}</span>
        </div>
      </div>
    </div>
        <div className="auth-row">
          <div className="item-container">
            <div className="item-wrapper">

              <InputWrapperRefacture label={t('checkout.recipientemail')}
                inputSize="medium"
                inputFieldType="text"
                type="email"
                id="email"
                name="email"
                description={t('checkout.recipientemaildescription')}
                onBlur={(e) => { handleOnBlur(e, "userEmail") }}
                placeholder={t('placeholder.recipientemail')}
                value={details.userEmail}
                onKeyPress={onTextFieldKeyed}
                invalidClass={((IsFilledRecipient.userEmail && !IsFilledRecipient.userEmail.filled) || IsInvalid.userEmail.invalid) ? "Invalid-input" : ""}
                aria-invalid={(IsFilledRecipient.userEmail && !IsFilledRecipient.userEmail.filled )}
                aria-describedby="email-errors"
                onChange={(e) => {handleOnChange(e.target.value, "userEmail") }}
                readOnly={""} />

              <span role="alert" id="email-errors">
                      {IsFilledRecipient.userEmail && !IsFilledRecipient.userEmail.filled && <ErrorBox>{t('error.recipientblankemailinput')}</ErrorBox>}
                {/* ? props.brandingElement?.mailValidError-----TODO */}
                {/* <ErrorBox> {getBrandedHtml('mailValidError')} </ErrorBox> : */}
                {IsInvalid.userEmail.invalid ? <ErrorBox>{t('login.incorrectemailinput')}</ErrorBox> : ""}
              </span>
            </div>
          </div>
        </div>
      </div>

  //for later implementation
  const giftDetails=()=>{
  return <>
     <div className="item-container">
            <div className="item-wrapper">

              <InputWrapperRefacture
              label={t('checkout.giftdeliverydate')}
              description={t('checkout.giftdeliverydatedescription')}
                inputSize="medium"
                inputFieldType="date"
                id="deliveryDate"
                // onfocus="(this.type='date')" 
                // onblur="(this.type='text')"
                onBlur={(e) => { handleOnBlur(e, "deliveryDate") }}
                name="deliveryDate"
                type="date"
                placeholder={t('checkout.giftdeliverydateplaceholder')}
                value={recipientDetails.deliveryDate}
                invalidClass={IsFilledRecipient.deliveryDate && "Invalid-input"}
                specs="text-input"
                onKeyPress={onTextFieldKeyed}
                aria-invalid={IsFilledRecipient.deliveryDate}
                aria-describedby="deliveryDate-blank-error"
                onChange={(e) => { setRecipientDetails(recipientDetails => ({ ...recipientDetails, deliveryDate: e.target.value })) }}
              />

              <span role="alert" id="deliveryDate-blank-error">{IsFilledRecipient.lastName && <ErrorBox>{t('register.blanklastname')}</ErrorBox>}</span>
            </div>
          </div>
          <div className="item-container">
            <div className="item-wrapper">

              <InputWrapperRefacture
              label={t('checkout.giftmessage')}
               inputSize="medium"
                inputFieldType="textbox"
                id="giftMessage"
                onBlur={(e) => { handleOnBlur(e, "giftMessage") }}
                name="giftMessage"
                type="textbox"
                value={recipientDetails.giftMessage}
                invalidClass={IsFilledRecipient.giftMessage && "Invalid-input"}
                specs="text-input"
                onKeyPress={onTextFieldKeyed}
                aria-invalid={IsFilledRecipient.giftMessage}
                aria-describedby="giftMessage-blank-error"
                onChange={(e) => { setRecipientDetails(recipientDetails => ({ ...recipientDetails, giftMessage: e.target.value })) }}
              />

              <span role="alert" id="giftMessage-blank-error">{IsFilledRecipient.lastName && <ErrorBox>{t('register.blanklastname')}</ErrorBox>}</span>
            </div>
          </div>
    </>
}
     
  return (
   <>
  {detailType ==="recipient"? recipient() : ""}
  {detailType ==="giftDetails" ? giftDetails() :""}
   </>
  );
}


