import React from 'react';
import "./../ThankyouPage.scss";



export function CustomerDetails(props) {  
 
    const BuyerDetails = () => {
        return <>
            <div className="details-block">
                <div className="item-container">
                    <div className="item-wrapper">
                        <h4>Your Details</h4>
                    </div>
                    <div className="item-wrapper">
                        <div className="item-field">
                            <p>{props?.purchaserName}</p>
                        </div>
                        <div className="item-field"> 
                            <p>{props?.purchaserEmail}</p> 
                        </div> 
                    </div>
                       
                </div>
            </div>
        </>
           
    }


  
    
    return (<> 
             {BuyerDetails()} 
    
    </>
    )
}