import React, { Component, Fragment, Suspense, useReducer, useEffect, useLayoutEffect, useState } from 'react';
import ReactDom from 'react-dom';
import ReactHtmlParser from 'html-react-parser';
import { Routes, Route , useParams} from 'react-router-dom';
import './general.scss'
import './normalize.css'
import './reset.css'
import Layout from './components/Layout';
import './i18n'
import PrivateRoute from './components/Utilities/PrivateRoute';
import { Payment } from './components/Order/Payment';
import { LandingPage } from './components/Order/LandingPage';
import { OrderComplete } from './components/Order/OrderComplete';
import { StripeResponseHandler } from './components/Order/Payment/StripeResponseHandler';
import { WorldpayResponseHandler } from './components/Order/Payment/WorldpayResponseHandler';
import TagManager from 'react-gtm-module'
import { WarningComponent } from './components/Authentication/WarningComponent/WarningComponent';
import { RefreshADToken } from './components/Utilities/TokenManager'
import { Loading } from './components/Utilities/Loading'
import { ErrorBox, ProcessErrors } from './components/Utilities/ErrorBox'
import { ProductOffer } from './components/ProductOffer/ProductOffer';
import { useAuth } from './components/Authentication/AuthenticationProvider';
import DesignReview from './components/UI/DesignReview/DesignReview';
import HeadProperties from './components/Layout/Head/HeadProperties';
import { ResetPassword } from './components/Authentication/ResetPassword/ResetPassword';
import { ClearCache } from './components/Caching/ClearCache';
import { APIGet, APIPost , getEnvironment } from './components/API/APIRequest';
import { Redirection } from './components/Redirection/Redirection';
import { Test } from './components/Test/Test';
import { TestProductOffer } from './components/Test/ProductOffer';
import {GoogleReCaptchaProvider, useGoogleReCaptcha} from 'react-google-recaptcha-v3';
import { CheckoutPage } from './components/Checkout/CheckoutPage';
import { ThankyouPage } from './components/Order/ThankyouPage/ThankyouPage';
import { formReducer, initialState, PaymentFormContext } from './components/Order/PaymentFormReducer';
import WhoIsItFor from './components/ProductOffer/WhoIsItFor';
import { Logout } from './components/Authentication/Logout';
import { PageNotFound } from './components/Site/PageNotFound'
import { DynamicScriptComponent } from './DynamicScriptComponent';
// const Payment = React.lazy(() => import('./components/Order/Payment'));

  export default function App(props) {
      const [branding, setBranding] = useState(null);
      const [isLoading, setIsLoading] = useState(true);
      const [reCaptchaKey, setReCaptchaKey] = useState(null);
      const [metaDataInjected, setMetaDataInjected] = useState(null);
      const [metaData, setMetaData] = useState(null);
      const environment = getEnvironment();
      const auth = useAuth();
      const [tagManagerAdded, setTagManagerAdded] = useState(null);
      const [fid, setFid] = useState(null);
      const host = window.location.hostname;
     
      useEffect(()=>{

         if(branding && fid){
            metaDataExtraction(fid);
         }

       },[branding, fid])

    
    const metaDataExtraction = (fid) => {
        APIGet({
              "controller": "metadata",
              "action": "checkoutmetadata",
              "identifier": fid,
              "environment": environment,
            "headers": [{
                "key": "UserToken",
                "value": auth.userData?.token ?? ""
            }]
          }).then((response) => {
            
             addMetaHeadScript(response.data);  
          
          }).catch(function (errors) {
            setMetaDataInjected("false")
               
          })
      }

     useEffect(()=>{
        //if not already injected will run
         if(metaDataInjected === "false"){
          tagManager(branding);
       
        }
       },[metaDataInjected]);


      const loadheaderBranding = async (metadata) => {
          await APIGet({
              "controller": "brand",
              "action": "getbranding",
              "environment": environment
          })
              .then(function (response) {
                  // handle success
                  setBranding(response.data.branding);
                  if (response.data.branding) {
                       sessionStorage.setItem("brandName", response.data.branding.id)
                      if (response.data.branding?.reCAPTCHAkey) {
                          setReCaptchaKey(response.data.branding?.reCAPTCHAkey)
                      }
                      else {
                          setReCaptchaKey("no_recaptcha")
                      }
                  } //this is also async..so may take time so directly pass in the function.
                    //add metadata script to head
                  htmlHeadScript(response.data.branding);
                  cssHeadScript(response.data.branding);
                  faviconCustom(response.data.branding)
                  setIsLoading(false);
               
              })
              .catch(function (error) {
                  // handle error
                  setReCaptchaKey("no_recaptcha")   //if recaptcha snippet throws an error.
                  setIsLoading(false)
                
              })

      }
   
    //injects gtm script along with executing scripts
    const tagManager = (branding, meta) => {
      if (branding) {
            const tagManagerArgs = { gtmId: branding.gtmId }
            TagManager.initialize(tagManagerArgs);    
        }
        setTagManagerAdded(true);
        
    }

   //get branding call on mounting
    useEffect(() => {

        loadheaderBranding(); 

     }, [])
    
   
    const htmlHeadScript = (br) => {
        const htmlHead = br.headHtml;
        const tempDiv = document.createElement('div');
        tempDiv.innerHTML=htmlHead;
        const scripts = tempDiv.querySelectorAll('script');

        scripts.forEach((script)=>{
           script.remove();
        })

        const modifiedHtml= tempDiv.innerHTML;
        return document.head.insertAdjacentHTML('beforeend', modifiedHtml);
    }

    const addMetaHeadScript = (meta) => {
        
        if (!metaDataInjected) {
            setTagManagerAdded(false);
            setMetaData(meta);
            setMetaDataInjected("true");
            tagManager(branding)
        }
        
    };


    const cssHeadScript = (data) => {
        const inlinecssHead = `<style type="text/css">${data.cssData} </style>`;
        return document.head.insertAdjacentHTML('beforeend', inlinecssHead);
    }
     

    const faviconCustom = (data) => {
        const favString = data.favicon || "";
        const favicons = favString.split(',');

        favicons.map((favicon, i) => {
            const link = `<link rel="icon" href=${favicon}" />`;
            return document.head.insertAdjacentHTML('beforeend', link);
        });

    }

    
    return (<> 
             {/*executing MetaData script */}
        <DynamicScriptComponent metaData={metaData} branding={branding} />
        <Suspense fallback={Loading}>
            <Layout auth={auth} brandingData={branding}>
                <Routes>
                        {host !== "localhost" ? "" :
                        <>
                            <Route exact path="/" element={<TestProductOffer />} />
                        </>}
                        <Route path='/logout' element={<Logout />} />
                        <Route path='/:environment/logout' element={<Logout />} />
                        <Route path='/checkout/:fid' element={<LandingPage brandingElement={branding} setFid={setFid} metaData={metaData} />} />     
                        <Route path='/payment/:fid' element={<PrivateRoute><Payment brandingElement={branding} setFid={setFid} metaData={metaData}/></PrivateRoute>} />
                        <Route path='/guest/:fid' element={<Payment guest="true" reCaptchaKey={reCaptchaKey} setFid={setFid} brandingElement={branding} metaData={metaData} />}/>
                        <Route path='/striperesponsehandler/:fid' element={<PrivateRoute><StripeResponseHandler brandingElement={branding} setFid={setFid} /></PrivateRoute>} /> 
                        <Route path='/worldpayresponsehandler/:fid' element={<PrivateRoute><WorldpayResponseHandler brandingElement={branding} setFid={setFid}/></PrivateRoute>} /> 
                        <Route path='/ordercomplete/:fid' element={<PrivateRoute><OrderComplete /></PrivateRoute>} />       
                        <Route path='/warning' element={<WarningComponent />} />
                        <Route path='/:environment/warning' element={<WarningComponent brandingElement={branding} />} />
                        <Route path='/clearcache' element={<ClearCache />} />
                        <Route path='/:environment/clearcache' element={<ClearCache brandingElement={branding} />} />
                        <Route path='/:environment/designreview' element={<DesignReview />} />
                        <Route path='/:environment/productoffer' element={<ProductOffer />} />
                        <Route path='/:environment/checkout' element={<CheckoutPage/>} />
                        <Route path='/thankyou/:orderid' element={<ThankyouPage brandingElement={branding} setFid={setFid} metaData={metaData} />} />
                        <Route path='/buyingforself/:fid' element={<WhoIsItFor />} />
                        <Route path='/redirect' element={<Redirection />} />
                        <Route path='*' element={<PageNotFound />} />
                    </Routes>
            </Layout>
        </Suspense>
        </>

    );

}
