import React, { useState, useEffect } from 'react';
import {useParams} from 'react-router-dom'
import { GetADToken } from '../Utilities/TokenManager';
import { APIPost } from '../API/APIRequest'
import { ErrorBox } from '../Utilities/ErrorBox'
import { GenericErrorBox } from '../UI/ErrorBox/GenericErrorBox';

export function LogWebActivity(props) {
    
    const [logged, setLogged] = useState(false);
    const [errors, setErrors] = useState([]);
    const { environment } = useParams();
    const {actionName} = props;
    const fid= sessionStorage.getItem("fid");
    const adtokenStr = sessionStorage.getItem("adtoken");  //gives you a string
    const adtokenObj = GetADToken();   //gives you an object

    const BasketItems = () => {
        let items = [];
        let itemsString ="";
        
        //creating a string of basket items 
        if(actionName==="Checkout started" || actionName==="Checkout completed" ){
            let basket = JSON.parse(sessionStorage.getItem(fid));
            if (basket?.lines) {
                basket.lines.forEach((variant, id) => {
                    items.push(variant?.productvariantid +"," + variant?.productvariantname)
                })
             }}
             
        itemsString = items?.join(";");
        return itemsString;
    }

    useEffect(() => {
        //regular web activity log
        if (adtokenObj) {
            logWebActivity();
        }

    }, [])

    useEffect(() => {
        //for checkout started and checkout completed logs
        if (actionName) {
            logWebActivity(actionName);
        }

    }, [actionName])


    const logWebActivity = (actionNameValue) => {
        if (!logged || (actionNameValue)) {
            const data = new FormData();
            
            if(actionNameValue){
                data.append('item', BasketItems());
                data.append('actionName', actionNameValue )
              
            }
            else{
                data.append('item', window.location.href);
            }
           
            data.append('userToken', adtokenStr);  // ad token object is being passed as a string, needed in both calls
         
            APIPost({
                "controller": "WebActivity",
                "action": "SaveWebActivity",
                "environment": environment,
                "headers": [{
                    "key": "UserToken",
                    "value": adtokenObj?.token ?? ""  // userToken string is being passed
                }],
                data: data
                }).then((response) => {
                setLogged(true);
            }).
                catch((error) => {
                    var unexpected = [];
                    unexpected.push("error.unexpected");
                    setErrors(unexpected);
            });
           
        }
    }
    return (<GenericErrorBox errors={errors}/>);
}
