import React, { useEffect } from 'react';
import './ErrorBox.css';
import { useTranslation } from 'react-i18next';
import '../../../i18n'
import { useAuth } from '../../Authentication/AuthenticationProvider';
import MessageBox from '../MessageBox/MessageBox';
import { format } from 'react-string-format';
import ReactHtmlParser from 'html-react-parser';


// const GenericErrorBox = (props) => {
//     return <div className="generic-error-box">
//         <span class="error-icon"></span>
//         <span> {props.children}</span>
//     </div>
// }

export function GenericErrorBox(props) {

    const auth = useAuth();
    const {url, thankyouPageUrl, refreshUrl, errors, type} = props;

    useEffect(() => {
        if (auth.setErrors) {
            auth.setErrors([]);
        }
    }, [window.location.href])

    const { t, i18n } = useTranslation();

    if (!errors || errors.length == 0) {
        return ('');
    }
   
   const errorList = []; 

    //wherever pushing the error messages array in genericErrorBox or pushing complete error response this will cover both.
    
    if (Array.isArray(errors)) {

        let arrayOfErrors = errors
        arrayOfErrors.map((error) => {
            if (error === "basket.unrecognisedbasketid") {
                let string=format(t(error), url)
                errorList.push(<span key={errorList.length}>{ReactHtmlParser(string)}</span>)
            }
            else if (error === "error.basketcompletedorder"){
                let string=format(t(error), thankyouPageUrl)
                errorList.push(<span key={errorList.length}>{ReactHtmlParser(string)}</span>)
            }
            else {
                errorList.push(<span key={errorList.length}>{t(error)}</span>)
            }
        });
    }
    else if (Array.isArray(errors?.messages)) {

        let arrayOfErrors = errors?.messages
        arrayOfErrors.map((error) => {
            if (error === "error.basketcompletedorder")  {
            
                let string=format(t(error), thankyouPageUrl)
                errorList.push(<span key={errorList.length}>{ReactHtmlParser(string)}</span>)
            }
            else if(error === "basket.unrecognisedbasketid" ){
                let string=format(t(error), url)
                errorList.push(<span key={errorList.length}>{ReactHtmlParser(string)}</span>)
            }
            else {
                errorList.push(<span key={errorList.length}>{t(error)}</span>)
            }
        });}
 else{  
    if(errors==="500_errors" && type==="paymentMethodErrors"){
        let string=format(t('error.paymentmoduleservererrors'), refreshUrl,  url)
        errorList.push(<span key={errorList.length}>{ReactHtmlParser(string)}</span>)
    }
}
    return (props.universal ? <div className="universal-error-box" >
    
        <span class="universal-error-icon"></span>
        <span aria-live="polite">
            {errorList}
        </span>
    </div> : <div className="item-container" >
        <MessageBox messageState="error" aria-live="polite">
            {errorList}
        </MessageBox>
    </div>
        // <ErrorBoxCS>{t('login.combinationdoesnotmatch')}</ErrorBoxCS>
    );
}

export function ProcessErrors(errors) {
    const errorList = [];

    const ProcessError = (error) => {
        switch (error) {
            case 'too_many_attempts':
                errorList.push('error.toomanyattempts');
                break;
            case 'invalid_grant':
                errorList.push('error.authenticationfailed');
                break;
            case 'error.resetpasswordtokenexpired':
                errorList.push('error.resetpasswordtokenexpired');
                break;
            case 'error.emailinuse':
                errorList.push('error.emailinuse');
                break;
            case 'error.incorrectemailpassword':
                errorList.push('error.incorrectemailpassword');
                break;
            case 'error.incorrectpassword':
                errorList.push('error.incorrectpassword');
                break;
            default:
                if (error.startsWith("error.")) {
                    errorList.push(error);
                }
                else {
                    errorList.push("error." + error);
                }
                break;
        }
    }

    if (errors.constructor === Array) {
        for (let i = 0; i < errors.length; i++) {
            ProcessError(errors[i]);
        }
    }
    //if (typeof errors === 'string') {
    //    errorList.push(errors);
    //}
    else if (errors?.code) {
        ProcessError(errors.code);
    }
    else if (errors) {
        ProcessError(errors);
    }
    return errorList;
}
