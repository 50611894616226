import { Fragment, useState, forwardRef, useRef, useEffect } from "react";
import { Link } from "react-router-dom";
import "../Inputs/InputRe.css";
import "./Dropdown.scss"
import useCreateUrl from '../../../Hooks/useCreateUrl';
import DOMPurify from "dompurify";

const SearchDropdown = forwardRef((props, ref) => {
  const { options, setValue, dropdownName, preSelectedCountryCode, valueProperty, val, keyProperty, nameProperty, description, edit, labelText, inputSize, text, label, btnText, optionalField, setButtonEvent, helpField, inputFieldType, route, value, onClickFunction } = props;
  const [showDropdown, setShowDropdown] = useState(false);
  const [filteredOptions, setFilteredOptions] = useState(options);
  const [searchTerm, setSearchTerm] = useState("");
  const [editable, setEditable] = useState(false);
  const [focused, setFocused] = useState(false);
  const [listItemEvent, setListItemEvent] = useState(false)
  const createUrl = useCreateUrl();
  const listRef = useRef(null);
  const searchInputRef = useRef(null)
  const [selectedIndex, setSelectedIndex] = useState(-1);
  let itemSelected= false;

  useEffect(() => {
   
    setFilteredOptions(options)}, [options]);

  useEffect(() => {
    if (listRef.current && listRef.current.children) {
      const listItems = listRef.current?.children;
      if (listItems.length > 0) {
        listItems[selectedIndex]?.classList.add('selected');
        for (let i = 0; i < listItems.length; i++) {
          if (i !== selectedIndex) {
            listItems[i].classList.remove('selected');
          }
        }

        const selectedListItem = listItems[selectedIndex];
        const listRect = listRef?.current?.getBoundingClientRect();
        const itemRect = selectedListItem?.getBoundingClientRect();
        const offset = itemRect?.top - listRect?.top;
        const listHeight = listRect?.bottom - listRect?.top;
        const itemHeight = itemRect?.bottom - itemRect?.top;

        if (offset < 0) {
          listRef.current.scrollTop += offset;
        } else if (offset + itemHeight > listHeight) {
          listRef.current.scrollTop += (offset + itemHeight) - listHeight;
        }
      }
    }

  
  }, [selectedIndex]);



  function handleKeyDown(event) {

    if (listRef.current && event.key === 'ArrowDown') {
      event.preventDefault();
      setSelectedIndex(prevIndex => {
        if (prevIndex === listRef.current.children.length - 1) {
          return prevIndex;
        }
        else if (prevIndex === 0) {

          return prevIndex + 1;
        }
        else {
          return prevIndex + 1;
        }
      });
    } else if (listRef.current && event.key === 'ArrowUp') {
      event.preventDefault();
      setSelectedIndex(prevIndex => {
        if (prevIndex === 0) {
          return prevIndex;
        } else {
          return prevIndex - 1;
        }
     });
    } else if (event.key === 'Enter') {
      if(selectedIndex !== -1){
      const currentOption = filteredOptions[selectedIndex];
      
      setValue(currentOption); 
      itemSelected=true;
      setSearchTerm("");
      handleOnClick(currentOption)

      var input = document.getElementById("delivery-country");
      if (input) {
        input.blur();
      }}
      // else{
      //   const currentOption = filteredOptions[0];
      //   setValue(currentOption); setSearchTerm(currentOption[nameProperty]); setShowDropdown(false)
      //   var input = document.getElementById("delivery-country");
      //   if (input) {
      //     input.blur();
      //    }
    // }
    }
    else if (event.keyCode === 9) {
    
      if (event.target.name === "delivery-country" || event.target.name === "billing-country") {
        setFocused(false)
      }

    }
  }

  //basic text input with a label along with a standard subtext with an exception of email input box

  let inputWidth = () => {
    switch (inputSize) {
      case 'full-width':
        return "full-width"
      case 'large':
        return "large"
      case 'medium':
        return "medium"
      case 'small':
        return "small"
      default:
        return "large"
    }
  }

  const handleOnFocus = () => {
    itemSelected=false;
    setFocused(true)
    setSearchTerm("")
    setShowDropdown(false)
    setListItemEvent(false)


  }



  const handleOnBlur = (e) => {
    setSelectedIndex(-1);   //reinitialising on blur
    // Handle onBlur logic for clicking outside the dropdown
    if (e?.relatedTarget === null || listItemEvent) {
      setFocused(false)
      setShowDropdown(false)
    }
      setFocused(false)

    if(searchTerm.length>0){
     setSearchTerm(""); 

    if(itemSelected===false){
      setValue(filteredOptions[0]); 
    }
    setFilteredOptions(options)
    }
    setListItemEvent(false)
  }

  const handleOnBlurButton = (e) => {
    
    if (e?.relatedTarget?.className === "search-dropdown") {
      setFocused(true)
      setShowDropdown(true)}
    else {
      setFocused(false)
      setShowDropdown(false)
    }
  }

  const handleOnBlurDropdown = (e) => {

    if (e?.currentTarget === "div.dropdown-wrapper") {
      setFocused(true)
      setShowDropdown(true)

    }
    else if (e?.relatedTarget === null || listItemEvent) {
      setFocused(false)
      setShowDropdown(false)
    }
  }

  const filterFunc = (value) => {

    if (value.length >= 1) {
      return options?.filter((op) =>
        (op[nameProperty]?.toLowerCase()?.startsWith(value.toLowerCase()) || (op?.aliases?.filter((alias) => { return alias.toLowerCase().startsWith(value.toLowerCase()) }).length > 0))
      )
    }
    else {
      return [...options];
    }

  }

  const handleSearch = (e) => {
     setSearchTerm(e.target.value); 
     setSelectedIndex(-1);  //the value that we enter in the search field
    // setValue(e.target.value); //for storing the selection
    let options = filterFunc(e.target.value)
    setFilteredOptions([...options]);

    // filtering options on the basis of search term
  }


  const handleOnClick = (option) => {
    //on click on scroller then going back to selcting an item from the list now working
    itemSelected=true;
    setListItemEvent(true)
    setShowDropdown(false)
    setSearchTerm("")
    setValue(option);
    setFocused(false)

    var input = document.getElementById("delivery-country");
    if (input) {
      input.blur();
    }
    var input1 = document.getElementById("billing-country");
    if (input1) {
      input1.blur();
    }
    // setSearchTerm(option[nameProperty]);
    setFilteredOptions(options)
  }

  const handleOnClickButton = (e) => {
    setShowDropdown(!showDropdown);
    handleOnFocus();   //so that the scroller works how it does in reference with the input field

  }
  
  //onMount
  useEffect(() => {
    if (preSelectedCountryCode) {
      let country = options?.find((item) => item.countrycode === preSelectedCountryCode)
      setValue(country)
    }
  }, [])

  const handleOnKeyDown = (e, option) => {
    if (e.key === "Enter") {
      handleOnClick(option)
    }
  }

  
  const labelField = () => {
    return <div class="label-wrapper">
      <div class="label-text">
        <label className="label" htmlFor={props.id}> {props.label} </label>
        {description ? <p className="label-description">{description}</p> : ""}
        {/*not present in code this particular spec */}
        {(props.specs === "search_input") && <p className="meta">Type first letters to find..</p>}
      </div>
    </div>
  }

  function highlightMatch(text, match) {
    return text.replace(
      new RegExp(`(${match})`, "gi"),
      '<span class="highlight">$1</span>'
    );
  }

  const renderSuggestions=(option, index)=>{
    let name=option[nameProperty];
    const regex = new RegExp(`(${searchTerm})`, 'gi');
    const splittedName = name.split(regex);
  
    return <li key={option[keyProperty] + Math.random()} aria-selected="false" role="listitem" onMouseDown={(e) => { handleOnClick(option) }}
      onKeyDown={(e) => { handleOnKeyDown(e, option) }} value={option[nameProperty]} tabindex="0" aria-label={option[nameProperty]}>
      <a class="dropdown-item" href="javascript:void(0)">
        {splittedName.map((part, index) => {
          if (regex.test(part)) { 
           
            return (
              <span
                key={index}
                dangerouslySetInnerHTML={{
                  __html: DOMPurify.sanitize(highlightMatch(part, searchTerm))   //mapping the companyname
                }}
              />
             
            );
          
          } else {
           
            return <span key={index}>{part}</span>;
          }
          
           })}
          </a></li>}



  const dropdown = () => {
    
    return ((focused && searchTerm?.length >= 0) || (showDropdown)) ?
      <ul ref={listRef} value={'Select' + " " + dropdownName} role="list" tabindex="0" className="search-dropdown" id="search-dropdown">
        {filteredOptions?.map((option, index) => {

          if (searchTerm?.length >= 1) {
           return renderSuggestions(option, index);
          }
          else {

        return <li key={option[keyProperty] + Math.random()} aria-selected="false" role="listitem"
              onMouseDown={(e) => { handleOnClick(option) }}
              onKeyDown={(e) => { handleOnKeyDown(e, option) }}
              value={option[nameProperty]} tabindex="0" aria-label={option[nameProperty]}>
              <a class="dropdown-item" href="javascript:void(0)"> {option[nameProperty]}</a>
            </li>
          }

        })}
        {props.id === "delivery-country" ? <li key="0" aria-selected="false" aria-label="others" tabindex="-1" role="option" onKeyDown={(e) => {
          if (e.key === "Enter") {
            setValue("Change region selected");
            window.location.href = route;
          }
        }} onMouseDown={(e) => { setValue("Change region selected"); window.location.href = route }}><a class="dropdown-item" href="#">Change region</a></li> : ""}
      </ul>

      : ""
  }

  const inputField = () => {
    return <input
      className={`${inputWidth()}`}
      onChange={(e) => { handleSearch(e) }}
      onFocus={(e) => { handleOnFocus(e) }}
      onKeyDown={handleKeyDown}
      onBlur={(e) => { handleOnBlur(e) }}
      type="text"
      disabled={edit ? !editable : false}
      label={label}
      autocomplete="off"
      role="combobox"
      id="searchInput"
      ref={searchInputRef}
      value={focused ? searchTerm : props.val}
      aria-expanded={showDropdown ? "true" : "false"}
      aria-haspopup="listbox" aria-autocomplete="list"
      {...props} />
  }


  return <>
    {(!label && !description && !optionalField && !helpField) ? !labelField() : labelField()}

    <div class="dropdown-wrapper" onBlur={(e) => handleOnBlurDropdown(e)}>
     
    <div className={`div-wrapper ${inputWidth()}  ${props.invalidClass ? props.invalidClass : props.disabled === "true" ? "" : (props.val?.length > 0) ? "filled" : ""}`}>
        <div className="input-wrapper">
          {inputField()}
          <button type="button" aria-hidden="true" tabindex="-1" onBlur={(e) => handleOnBlurButton(e)} onClick={(e) => { handleOnClickButton(e) }} >
            <span aria-hidden="true" tabindex="-1" className="downArrow"></span>
          </button>
        </div>
        {filteredOptions ? dropdown() : " "}
      </div>
    </div>
  </>
})
export default SearchDropdown;