import { t } from 'i18next';
import React, { useRef, useState, useEffect } from 'react';
import { format } from 'react-string-format';


import "./../ThankyouPage.scss";

export function BillingDetails(props) { 
    return (
        <div className="details-block">
            <div className="item-container">
                <div className="item-wrapper">
                    <h4>Billing Details</h4>
                </div>

                {props?.billingAddress &&
                    <>
                        {props.billingAddress?.locationId !== 0 &&
                          
                                <div className="item-wrapper">
                                    <div className="item-field">
                                        <p><strong>Billing Address</strong></p>
                                         <p> {props.billingAddress?.companyName && props?.billingAddress.companyName + ', '}
                                             {props.billingAddress?.address1 && props?.billingAddress.address1 + ', '}
                                            {props?.billingAddress?.address2 && props?.billingAddress.address2 + ', '}
                                            {props?.billingAddress?.address3 && props?.billingAddress.address3 + ', '}
                                            {props?.billingAddress?.town && props?.billingAddress.town + ', '}
                                            {props?.billingAddress?.county && props?.billingAddress.county + ', '}
                                            {props?.billingAddress?.country && props?.billingAddress.country + ', '}
                                            {props?.billingAddress?.postcode}
                                        </p>
                         
                            

                                    </div>
                                </div>
                          }
                    </>
                }
                <div className="item-wrapper">
                    <div className="item-field">
                        <p><strong>Payment</strong></p>
                    </div>
                    <div className="payment-method ">
                        <img
                            src={`/assets/paymenttypes/logos/${props.paymentType === 3 ? 'directdebit' :
                                props.paymentType === 10010 ? 'paypal' :
                                    (props.paymentType === 7 || props.paymentType === 2) && props?.cardType
                                }.svg`}
                            alt="logo"
                        />
                        {props.paymentType === 3 && <p>Paid by Direct Debit  </p>}
                        {(props.paymentType === 7 || props.paymentType === 2) && (
                            <p> Credit/debit card ending in <strong> {props?.cardNumber} </strong></p>
                        )}
                        {props.paymentType === 10010 && <p>Paid using Paypal</p>}
                    </div>
                    {props.paymentType === 3 && <div className="item-container">
                        <div className="border-seperator"> </div>
                        <div className="item-wrapper">  
                        <div><strong>Account holder:</strong> {props.directDebit.accountholder} </div>
                        <div> <strong>Bank:</strong> {props.directDebit.bankname}</div>
                        <div> <strong>Account number:</strong>  {props.directDebit.accountnumber} </div>
                        <div> <strong>Sort code:</strong>  {props.directDebit.sortcode} </div>
                        </div>
                            <div className="border-seperator"> </div>
                    </div>}

                </div>
                {props?.nextPayment &&
                    <div className="item-field three-columns">
                        <div className="one-column"><p><strong>Next Payment Date</strong> </p><p>{props?.nextPayment?.nextpaymentdate}</p></div>
                        <div className="one-column"><p><strong>Payment amount</strong></p><p>{props?.currency}{props?.nextPayment?.amount?.toFixed(2)}</p></div>
                        <div className="one-column"><p><strong>Frequency</strong></p><p>{format(t('thankyoupage.frequencyvalue'), props?.nextPayment?.frequency, props?.nextPayment?.unit)}</p></div>
                    </div>
                }
            </div>
        </div>
    )
}