import { useTranslation} from 'react-i18next';
import {useEffect, useState, useContext, useRef, forwardRef, useImperativeHandle} from 'react';
import '../../../i18n'
import InputWrapperRefacture from '../../UI/Inputs/InputWrapperRefacture';
import Checkbox from '../../UI/Checkbox/Checkbox';
import { accountNumberRegex, sortCodeRegex } from '../../Utilities/EmailRegex';
import { APIGet, APIPost } from '../../API/APIRequest';
import setStateAsync from '../../Utilities/SetStateAsync';
import  ErrorBox  from '../../UI/ErrorBox/ErrorBox';
import { GetBasket } from './Basket';
import { PaymentFormContext, formState } from '../PaymentFormReducer';
import ReactHtmlParser from 'html-react-parser';
import {  gtmEventTriggers } from '../../Utilities/GtmFunctions';

export const UpdateDirectDebitPaymentRequest = (props) => {
    return new Promise((resolve, reject) => {
       APIPost({
            "controller": "DirectDebit",
            "action": "CompleteDirectDebitSetup",
            "environment": null,                      
            "data": {
                fid: props.basket.id
            }
        }).then((response) => {
            resolve(response);
        }).catch((error) => {
            reject(error);
        });
    });
}

export const DirectDebitForm = forwardRef((props, ref) => {

    const { t, i18n } = useTranslation();
    const {  setddDetails, ddDetails, setIsAuthDebitCardChecked, setErrorStates, errorStates, showError, BankValidation} = props;
    const [IsBlank, setIsBlank] = useState({ accountholder: false, bankname: false, accountnumber: false, sortcode: false });
    const [IsInvalid, setIsInvalid]= useState({accountholder:false, bankname:false, accountnumber:false, sortcode:false});  
    const [errors, setErrors] = useState([]);
    const fid = props.basket.id; 
    const [accountDetails, setAccountDetails] = useState({accountholder:"", bankname:"", accountnumber:"", sortcode:""});
    const [isChecked, setIsChecked] = useState(false);
    const { bankVal } = props;

    const formatInputSortCode = (input) => {
        const formatted = input.replace(/(\d{2})(\d{2})(\d{2})/, '$1-$2-$3');
        return formatted;
    };

    useImperativeHandle(ref, () => ({
        confirmPayment(event){
            APIGet({
                "controller": "Basket",
                "action": "GetOrderId",
                "environment": null,                      
                "identifier": fid
            }).then((response) => {
                 
                  gtmEventTriggers("add_payment_info")
                  
                  window.location.href = window.location.protocol + "//" + window.location.host + "/thankyou/" + response.data.OrderId;
            }).catch((error) => {
                throw(error);
            });            
        },
        errors
    }));


    useEffect(()=>{
        setErrorStates(errorStates => ({ ...errorStates, authDD: false, isBlankAccountNameDD: false, isBlankBankDD:false, invalidAccountNumberDD:false, invalidSortcodeDD:false, invalidFormatAccountNumberDD:false, invalidFormatSortcodeDD:false, isBlankAccountNumberDD:false, isBlankSortCodeDD:false}))    //resetting parent error states 
        setddDetails(ddDetails=>({ ...ddDetails, bankname: ""}))   //resetting parent dd bankname field
    },[])


    useEffect(() => {
    
       setIsAuthDebitCardChecked(isChecked);
          if (isChecked) {
             setErrorStates(errorStates => ({ ...errorStates, authDD: false }))
           }
      }, [isChecked, setIsAuthDebitCardChecked, setErrorStates])


    const handleOnBlur=(e, fieldType)=>{
        let value=e.target.value
        setStateAsync(() => {
           
            if (value.length < 1) {

                setIsInvalid(IsInvalid => ({ ...IsInvalid, [fieldType]: false }))
                setErrorStates(errorStates=>({...errorStates, invalidFormatAccountNumberDD:false}));  //parent states
                setErrorStates(errorStates=>({...errorStates, invalidFormatSortCodeDD:false}));
                setIsBlank(IsBlank => ({ ...IsBlank, [fieldType]: true }))               //local states
                

            }
            else {

                if(fieldType==="accountholder"){
                    setErrorStates(errorStates => ({ ...errorStates, isBlankAccountNameDD: false }));
                }

                if(fieldType==="bankname"){
                    setErrorStates(errorStates => ({ ...errorStates, isBlankBankDD: false }));
                }

                setIsBlank(IsBlank => ({ ...IsBlank, [fieldType]: false }))
                setddDetails(ddDetails=>({ ...ddDetails, [fieldType]: value}))  //for parent validation setting values on blur

                if (fieldType === "accountnumber") {

                    setErrorStates(errorStates=>({...errorStates, invalidAccountNumberDD:false}));
                    setErrorStates(errorStates => ({ ...errorStates, isBlankAccountNumberDD: false }));  //parent Validation
               
                    if ((value?.match(accountNumberRegex) || []).length <= 0) {
                              setIsInvalid(IsInvalid => ({ ...IsInvalid, accountnumber: true }))
                              setErrorStates(errorStates=>({...errorStates, invalidFormatAccountNumberDD:true}));
                    }
                    else {
                              setIsInvalid(IsInvalid => ({ ...IsInvalid, accountnumber: false }))
                              setErrorStates(errorStates=>({...errorStates, invalidFormatAccountNumberDD:false}));
                      }

                }
                else if (fieldType === "sortcode") {

                    setErrorStates(errorStates=>({...errorStates, invalidSortCodeDD:false}));
                    setErrorStates(errorStates => ({ ...errorStates, isBlankSortCodeDD: false }));  //final validation

                    if ((value?.match(sortCodeRegex) || []).length <= 0) {
                        setIsInvalid(IsInvalid => ({ ...IsInvalid, sortcode: true }))
                        setErrorStates(errorStates=>({...errorStates, invalidFormatSortCodeDD:true}));
                     }
                    else {
                        setIsInvalid(IsInvalid => ({ ...IsInvalid, sortcode: false }))
                        setErrorStates(errorStates=>({...errorStates, invalidFormatSortCodeDD:false}));
                    }

                }
            }
        })
    }

    const handleOnChange=(value, keyName)=>{

        //assigning values to the local as well as parent states here
        
        setAccountDetails(accountDetails => ({ ...accountDetails, [keyName]: value}))
        setddDetails(ddDetails=>({ ...ddDetails, [keyName]: value}))
        
    }


    
    return (
     <>
        <div className="item-container">
            <div className="item-wrapper">
                <h5 className="module-subheading">{t('directdebitform.title')}</h5>
                <img src='assets/paymenttypes/logos/directdebit.svg' alt={t('paymenttype.directdebit')}  height="100px" width="120px" />
            </div>
            <div className="item-wrapper">
                <p>{ReactHtmlParser(props?.ddTerms)}</p>
            </div>
            </div> 

           <div className="item-container">
                <div className="item-wrapper">
                    <InputWrapperRefacture
                        label={t('directdebitform.accountholder')}
                        inputSize="medium"
                        id="accountholder"
                        inputFieldType="text"
                        specs="text_input"
                        name="accountholder"
                        type="text"
                        invalidClass={((IsBlank.accountholder) || errorStates?.isBlankAccountNameDD) && "Invalid-input"}
                        aria-invalid={IsBlank.accountholder}
                        aria-describedby="accountholder-blank-error"
                        value={accountDetails.accountholder}
                        onBlur={(e) => { handleOnBlur(e, "accountholder" ) }}
                        onChange={(e)=> {handleOnChange(e.target.value, "accountholder")}}
                      />
        
                     {(IsBlank.accountholder || errorStates?.isBlankAccountNameDD) && <span role="alert" id='accountholder-blank-error'> <ErrorBox>{t('directdebitform.blankaccountholder')}</ErrorBox></span>}
                </div>
            </div> 

            <div className="item-container">
                <div className="item-wrapper">
                    <InputWrapperRefacture
                        label={t('directdebitform.bankname')}
                        inputSize="medium"
                        id="bankname"
                        inputFieldType="text"
                        specs="text_input"
                        name="bankname"
                        type="text"
                        invalidClass={(IsBlank.bankname || errorStates?.isBlankBankDD) && "Invalid-input"}
                        aria-invalid={IsBlank.bankname}
                        aria-describedby="bankname-blank-error"
                        value={ddDetails?.bankname || accountDetails?.bankname }
                        onBlur={(e) => { handleOnBlur(e, "bankname") }}
                        onChange={(e)=> {handleOnChange(e.target.value, "bankname")}}
                    />

                   {(IsBlank.bankname || errorStates?.isBlankBankDD) && <span role="alert" id='bankname-blank-error'><ErrorBox>{t('directdebitform.blankbankname')}</ErrorBox></span>}

                </div>
            </div>

            <div className="item-container cardDetails-row">
                <div id="div-accountnumber">
                    <div className="item-container">
                        <div className="item-wrapper">
                            <InputWrapperRefacture
                                label={t('directdebitform.accountnumberlabel')}
                                inputSize="full-width"
                                id="accountnumber"
                                inputFieldType="text"
                                specs="text_input"
                                name="accountnumber"
                                type="text"
                                maxLength="8"
                                invalidClass={(IsBlank.accountnumber || IsInvalid.accountnumber || errorStates.isBlankAccountNumberDD || ( errorStates.invalidAccountNumberDD && !errorStates.invalidFormatAccountNumberDD)) && "Invalid-input"}
                                aria-invalid={IsBlank.accountnumber}
                                aria-describedby="accountnumber-blank-error"
                                onBlur={(e) => { handleOnBlur(e, "accountnumber") }}
                                value={accountDetails.accountnumber}
                                onChange={(e)=> {handleOnChange(e.target.value, "accountnumber")}}
                            />
                          
                            {(IsBlank.accountnumber || errorStates.isBlankAccountNumberDD) && <span role="alert" id='accountnumber-blank-error'> <ErrorBox>{t('directdebitform.blankaccountnumber')}</ErrorBox></span>}
                           
                            {(IsInvalid.accountnumber || errorStates.invalidFormatAccountNumberDD) && <span role="alert" id='accountnumber-invalid-format-error'> <ErrorBox>{t('directdebitform.invalidaccountnumberformat')}</ErrorBox></span>}

                            {(errorStates.invalidAccountNumberDD && !errorStates.invalidFormatAccountNumberDD) && <span role="alert" id='accountnumber-invalid-error'> <ErrorBox>{t('directdebitform.invalidaccountnumber')}</ErrorBox></span>}
                          
                          </div>
                    </div>
                </div>


                <div id="div-sortcode">
                    <div className="item-container">
                        <div className="item-wrapper">
                            <InputWrapperRefacture
                                label={t('directdebitform.sortcode')}
                                inputSize="small"
                                id="sortcode"
                                inputFieldType="text"
                                specs="text_input"
                                name="sortcode"
                                type="text"
                                maxLength="8"
                                value={formatInputSortCode(accountDetails.sortcode)}
                                invalidClass={(IsBlank.sortcode || IsInvalid.sortcode || errorStates.isBlankSortCodeDD || ( errorStates.invalidSortCodeDD && !errorStates.invalidFormatSortCodeDD)) &&  "Invalid-input"}
                                aria-invalid={IsBlank.sortcode}
                                aria-describedby="sortcode-blank-error"
                                onBlur={(e) => { handleOnBlur(e, "sortcode") }}
                                onChange={(e)=> {handleOnChange(e.target.value, "sortcode")}}
                            />
                           
                            {(IsBlank.sortcode || errorStates.isBlankSortCodeDD) && <span role="alert" id='sortcode-blank-error'> <ErrorBox>{t('directdebitform.blanksortcode')}</ErrorBox></span>}
                            
                            {(IsInvalid.sortcode || errorStates.invalidFormatSortCodeDD) &&<span role="alert" id='sortcode-invalid-format-error'><ErrorBox>{t('directdebitform.invalidsortcodeformat')}</ErrorBox></span>}

                            {(errorStates.invalidSortCodeDD && !errorStates.invalidFormatSortCodeDD) && <span role="alert" id='sortcode-invalid-error'><ErrorBox>{t('directdebitform.invalidsortcode')}</ErrorBox></span>}
                           
                          </div>
                     </div>
                </div>
            </div>


            
            <div className="item-container">
                <div className="item-wrapper">
                    <Checkbox 
                        checked={isChecked}
                        onChange={() => { setIsChecked(!isChecked) }}
                        id="debitAuth"
                        name="debitAuth"
                        label={t('directdebitform.accountholderauth')}
                    />

                    {showError && (!isChecked || errorStates.authDD) && (
                        <span role="alert" id="authDD-not-selected">
                            <ErrorBox>{t('directdebitform.accountholderauthError')}</ErrorBox>
                        </span>
                    )}

                </div>
            </div>
     </>

    );
})