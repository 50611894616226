import InputWrapperRefacture from "../../UI/Inputs/InputWrapperRefacture";
import "./PromoCode.scss";
import { useState, useEffect } from "react";
import { useTranslation } from 'react-i18next';

import ErrorBoxCS from '../../UI/ErrorBox/ErrorBox';


export default function PromoCode() {

    const [showField, setShowField] = useState(false);
    const [promoCode, setPromoCode] = useState("");
    const [promoText, setPromoText] = useState("I have a promo code");
    const [IsBlank, setIsBlank] = useState({ promoCode: false })
    const [IsInvalid, setIsInvalid] = useState({ promoCode: false })
    const { t, i18n } = useTranslation();


    const promoField = () => {
      return <div className="promo-field">
            <div className="item-container">
                <div className="item-wrapper">
                    <InputWrapperRefacture
                        label=""
                        inputSize="full-width"
                        inputFieldType="text"
                        btnText="Apply"
                        value={promoCode}
                        id="promoCode"
                        name="promoCode"
                        placeholder="Enter Promo Code here"
                        onClickFunction={() => submitPromoCode(promoCode)}
                        // ref={inputRef}
                        // onBlur={(e) => { handleOnBlur(e) }}
                        // setButtonEvent={setButtonEvent}
                        aria-invalid={IsBlank.promoCode || IsInvalid.promoCode}
                        aria-describedby="promocode-error"
                        invalidClass={(IsBlank.promoCode || IsInvalid.promoCode) ? "Invalid-input" : ""}
                        onChange={(e) => setPromoCode(e.target.value)} />
                    <span role="alert" id="promocode-error">
                        {IsBlank.promoCode && <ErrorBoxCS>{t('productoffer.blankpromocode')}</ErrorBoxCS>}
                        {IsInvalid.promoCode && <ErrorBoxCS>{t('productoffer.invalidpromocode')}</ErrorBoxCS>}
                    </span>
                </div>
            </div>
        </div>

    }

    const submitPromoCode = (e) => {
        if (Validated()) {

        }
    }


    const Validated = () => {

        let valid = true;
        setIsInvalid({ promoCode: false })
        setIsBlank({ promoCode: false })

        if (promoCode.length < 1) {
            setIsBlank({ promoCode: true });
            valid = false;
        }
        else if (promoCode.length > 1) {
            // && IsInvalid.promoCode
            setIsInvalid({ promoCode: true });
            valid = false;
        }
        return valid;

    }

    return (
        <div>
            <button className="dropdown" onClick={() => { setShowField(!showField) }}>I have a promo code<span className={`${showField ? "upArrow" : "downArrow"}`}></span></button>
            {showField ? promoField() : ""}
        </div>
    )
}