import React, { useEffect } from 'react';

function LoqateAddressFinder(props) {
   

  useEffect(() => {
        const fields = [
            { element: "search", field: "" , mode: window.pca.fieldMode.SEARCH},
            { element: "COMPANY", field: "Company", mode: window.pca.fieldMode.POPULATE | window.pca.fieldMode.PRESERVE },
            { element: "ADDRESS1", field: "Line1" },
            { element: "ADDRESS2", field: "Line2", mode: window.pca.fieldMode.POPULATE },
            { element: "ADDRESS3", field: "Line3", mode: window.pca.fieldMode.POPULATE },
            { element: "CITY", field: "City", mode: window.pca.fieldMode.POPULATE },
            { element: "COUNTY", field: "Province", mode: window.pca.fieldMode.POPULATE },
            { element: "POSTCODE", field: "PostalCode" },
            { element: "COUNTRY", field: "CountryName", mode: window.pca.fieldMode.COUNTRY }
        ];
        
        const options = props.options;  //options for loqate

        const control = new window.pca.Address(fields, options);   //new address instance

        control.listen("populate", function(address, variations) {   
            props.setLoqateProps(address);                          //gets use the address field on selection
         });

        control.listen("load", function() {   //wait for DOM to be ready
            control.setCountry("GBR");       // Set the default country (example: "GBR" for United Kingdom)
        });

        control.load();

        return () => {
            control.destroy();    //cleaning up
        };

    }, [props.options]); 

 

    return (<></> );
}

export default LoqateAddressFinder;
