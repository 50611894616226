import React, { useRef, useState, useEffect } from 'react';
import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next';
import "./../ThankyouPage.scss";
import useFindHeight from '../../../../Hooks/useFindHeight';


export function OrderSummary(props) {
 
    const [observedDiv, elementHeight] = useFindHeight();
    return (
        <div className="details-block">
            <div className="item-container orderSummary-container">
              <div  className="item-wrapper">
                    <h4>Order Summary</h4>
                    {props?.items?.map((item, index) => (
                        <> <div className="two-columns-row">
                            <div className="module-column">
                                <div className="module-block">
                                    <h3 className="module-subheading">{item.displayname ? item.displayname : item.shortdescription}  </h3>
                                    <span className="module-quantity-block">  x{item.quantity}</span>
                                </div>

                            </div>
                            <div className="module-price">
                                {props?.currencysymbol}{item?.price?.toFixed(2)}
                            </div>
                        </div>
                            <div className="border-seperator"> </div>
                        </>
                    ))} 
                      
            </div>
                   
                <div className="item-wrapper">
                   
                    <div className="div-wrapper">
                <p>Sub-total</p>
                        <p>{props?.currencysymbol}{props?.net?.toFixed(2)}</p>
            </div>
            <div className="div-wrapper">
                <p>Tax</p>
                        <p>{props?.currencysymbol}{props?.tax?.toFixed(2)}</p>
                    </div>
                    {props.delivery &&
                        <div className="div-wrapper">
                            <p>Delivery</p>
                            <p>{props?.currencysymbol}{props?.delivery?.toFixed(2)}</p>
                        </div>
                 }
            <div className="border-seperator"></div>
            <div className="div-wrapper">
                        <p><strong>Total Cost</strong></p>
                        <p>{props?.currencysymbol}{props?.total?.toFixed(2)}</p>
            </div>
         </div>
            </div>
        </div>
    )
}